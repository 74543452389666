import React, { useEffect } from "react";
import AppRoutes from "./routes/Routes";
import ResponsiveAppBar from "./pages/HomePage/HomePage.js";
import { useAuthContext } from "./context/AuthContext";
import { Navigate } from "react-router-dom";

export const AuthRedirect = () => {
  const { user, isLoading } = useAuthContext();

  useEffect(() => {
    const path = window.location.pathname;

    if (isLoading) return;

    if (!user && path === "/") {
      <Navigate to="/login" replace />;
    }
    if (user && (path === "/" || path === "/login")) {
      <Navigate to="/calendar" replace />;
    }
  }, [user, isLoading]);

  return null;
};

export default function App() {
  return (
    <>
      <ResponsiveAppBar />
      <AppRoutes />
    </>
  );
}
