import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FB_APP } from '../constant';
import { useLocation, useNavigate } from 'react-router-dom';
import makeRequest from '../makeRequest';
import ProductList from './Products/ProductList';
import ProductForm from './Products/ProductForm';
import ChannelList from './Products/ChannelList';
import MUIModal from '../components/MUIModal';
import MUILoader from '../components/MUILoader';
import {
    Alert,
    message,
  } from "antd";

const Settings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState([]);
    const [instaChannels, setInstaChannels] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [viewModal, setViewModal] = useState(false);

    const handleFacebookConnect = () => {
        const redirectUri = encodeURIComponent(`${window.location.href}`);
        const scopes = [
            'pages_show_list',
            'public_profile',
            'email',
            'business_management',
            'pages_read_engagement',
            'instagram_basic',
            'instagram_manage_insights',
            'ads_management',
            'catalog_management',
            'instagram_shopping_tag_products',
            'instagram_content_publish',
            'pages_manage_metadata',
            'instagram_manage_comments',
            'pages_manage_posts',
            'pages_manage_engagement',
            'pages_manage_instant_articles',
            'publish_video',
            // 'threads_manage_replies',
            // 'threads_read_replies',
        ];

        setLoading(true);
        const fbOAuthUrl = `https://www.facebook.com/v18.0/dialog/oauth?response_type=code&state=${Date.now()}&scope=${scopes.join(',')}&display=popup&client_id=${FB_APP}&redirect_uri=${redirectUri}`;
        window.location.href = fbOAuthUrl;

    }


    const fetchData = () => {
        setLoading(true);
        makeRequest({
            url: `/api/products?populate=*&publicationState=preview`,
            method: 'GET',
        })
            .then(respData => {
                if (respData?.data) {
                    setProducts(respData.data);
                }
            })
            .catch(error => {
                console.error('Error fetching products:', error);
            }).finally(() => {
                setLoading(false);
            });
        setLoading(true);
        makeRequest({
            url: `/api/channels?populate=*&publicationState=preview`,
            method: 'GET',
        })
            .then(respData => {
                if (respData?.data) {
                    setChannels(respData.data);
                }

            })
            .catch(error => {
                console.error('Error fetching channels:', error);
            }).finally(() => {
                setLoading(false);
            });
    }


    const handleEdit = (productData) => {
        setProduct(productData)
        setShowForm(true);

    };

    const handleDelete = async (id) => {
        if (!id) return;

        setLoading(true);
        await makeRequest({
            method: 'DELETE',
            url: `/api/products/${id}`,
            // data: { data: product ? { ...postData, id: product.id } : postData }
        }).then(respData => {
            if (respData?.data) {
                fetchData();
                handleDeleteModalClose();

                // handleModalClose();
            }
        }).catch(err => {
            console.error(`Product delete Error`, err);

        }).finally(() => {
            setLoading(false);
        });
    };

    const handleConfirmDelete = async (productData) => {
        setProduct(productData);
        setViewModal(true);
    }

    const handleProductSubmit = async (productData) => {
        const existingChannelIds = product ? product.attributes.channels.data.map(c => c.id) : [];

        const connectChannels = productData.channels.map(cItem => ({
            id: cItem.id,
            position: { end: true }
        }));

        const disconnectChannels = existingChannelIds
            .filter(id => !productData.channels.some(c => c.id === id))
            .map(id => ({ id }));

        const postData = {
            name: productData.name,
            details: productData.description,
            url: productData.url,
            channels: {
                connect: connectChannels,
                disconnect: disconnectChannels,
            },
        };
        setLoading(true);
        await makeRequest({
            method: product ? 'PUT' : 'POST',
            url: product ? `/api/products/${product.id}` : '/api/products',
            data: { data: product ? { ...postData, id: product.id } : postData }
        }).then(respData => {
            if (respData?.data) {
                fetchData();
                handleModalClose();
            }
        }).catch(err => {
            console.error(`Product submit Error`, err);

        }).finally(() => {
            setLoading(false);
        });
    };

    const handleModalClose = () => {
        setShowForm(false)
        if (product) {
            setProduct(null)
        }
    }
    const handleDeleteModalClose = () => {
        setViewModal(false);
        if (product) {
            setProduct(null)
        }
    }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            setLoading(true);
            makeRequest({
                url: `/api/facebook?code=${code}&redirect_uri=settings`,
                method: 'GET',
            })
                .then(response => {
                    if(response?.account){
                        navigate(window.location.pathname, { replace: true });
                    }
                })
                .catch(error => {
                    console.error('Error during Facebook login:', error);
                }).finally(()=>{
                    setLoading(false);
                });
        }
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <Box sx={{ padding: 4, margin: 'auto' }}>
            {loading ? (
                <MUILoader open={loading} />
            ) : (
                <Box display="flex" sx={{ marginTop: 4, justifyContent: 'space-between' }}>
                    <Typography variant="h5">Settings</Typography>
                    <Button variant="contained" color="primary" onClick={() => { handleFacebookConnect() }}>
                        Connect Facebook
                    </Button>
                </Box>
            )}

            <ProductList
                products={products}
                onEdit={handleEdit}
                onDelete={handleConfirmDelete}
                handleBtnClick={() => { setShowForm(true) }}
            />

            {/* <ChannelList channels={channels} /> */}


            <MUIModal
                maxWidth='sm'
                title={product ? 'Update Product' : 'Create Product'}
                open={showForm}
                handleClose={handleModalClose}
            >
                <ProductForm product={product} onSubmit={handleProductSubmit} />
            </MUIModal>

            <MUIModal
                open={viewModal}
                handleClose={() => {
                    handleDeleteModalClose()
                }}
                title="Do you want to proceed?"
            >
                <Box pt={1}>
                    <Typography variant='title'>
                        {`You are about to delete the product ${product?.attributes?.name}. This action cannot be undone.`}
                    </Typography>

                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} flexWrap="wrap" py={1}>
                        <Button
                            size="small"
                            variant="outlined"
                            type="submit"
                            onClick={handleDeleteModalClose}
                            sx={{ mt: 1 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            color='error'
                            variant="contained"
                            type="submit"
                            onClick={() => { handleDelete(product?.id) }}
                            sx={{ mt: 1 }}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </MUIModal>


            {error && (
                <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
                    {error}
                </Typography>
            )}

        </Box>
    );
};

export default Settings;
