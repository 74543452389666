import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import MUIAutocomplete from '../../components/MUIAutoComplete';
import { BASE_URL } from '../../constant';

const ProductForm = ({ product, onSubmit }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [channels, setChannels] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const productData = {
            name,
            description,
            url,
            channels
        };
        onSubmit(productData);
    };


    useEffect(() => {
        if (product) {
            setName(product.attributes.name || '');
            setDescription(product.attributes.details || '');
            setUrl(product.attributes.url || '');
            const channelData = product.attributes.channels.data.map(cItem=>({
                id: cItem.id,
                label: `${cItem.attributes['display_name']} - ${cItem.attributes.channel_type}`,
                data: cItem
             }))
            setChannels(channelData);
        }
    }, [product]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex', flexDirection: 'column', gap: 2,
                // width: 400,
                margin: 'auto',
                paddingTop:1
            }}
        >
            {/* <Typography variant="h6">{product ? 'Update Product' : 'Create Product'}</Typography> */}

            <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />

            <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                required
            />

            <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="url"
                required
            />

            <MUIAutocomplete
                multiple
                label="Channels"
                selectedValue={channels}
                apiRequest={{
                   url:`/api/channels?populate=*&publicationState=preview`,
                   method:'GET'
                }}
                fieldKey="display_name"
                onSelect={(channelObj) => {
                    setChannels(channelObj);
                }}
                // fixedOptions={fixedOptions}
                autoHighlight={true}
            />

            <Button
                type="submit"
                variant="contained"
                color="primary"
            >
                {product ? 'Update Product' : 'Create Product'}
            </Button>
        </Box>
    );
};

export default ProductForm;
