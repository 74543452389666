import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import MUILoader from "../components/MUILoader";


const PrivateRoute = () => {
  const { isLoading, user } = useAuthContext();

  if (isLoading) {
    return <MUILoader open />;
  }
  if (user) {
    return <Outlet />;
  }
  return <Navigate to="/login" replace/>;
};

export default PrivateRoute;
