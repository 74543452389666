import React, { useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { BASE_URL } from "../../constant";
import { getToken, setToken, removeToken } from "../../helpers";
import makeRequest from "../../makeRequest";

const fetchUserDetails = async (token) => {
  const response = await makeRequest({
    method: "GET",
    url: `${BASE_URL}/api/users/me`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(() => {
      const savedUser = localStorage.getItem("user");
      return savedUser ? JSON.parse(savedUser) : undefined;
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const user = localStorage.getItem('user');
    if(authToken && user){
      setUserData(JSON.parse(user));
      setIsLoading(false);
    }else if (authToken) {
      fetchUserDetails(authToken)
        .then((userData) => {
          setUserData(userData);
          localStorage.setItem("user", JSON.stringify(userData));
        })
        .catch((err) => {
          console.log(`Failed to fetch userDetails`, err);
          setUserData(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleLogin = (newUser, token) => {
    setToken(token);
    setUserData(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const handleLogout = () => {
    setUserData(null);
    localStorage.removeItem('user');
    removeToken();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        user: userData,
        setUser: handleLogin,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
