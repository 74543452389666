import React from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const CustomModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
}));

const LoaderContent = styled('div')({
  backgroundColor: '#fff', 
  borderRadius: '8px', 
  padding: '12px',
  outline:'none',
});

const MUILoader = ({ open }) => {
  return (
    <CustomModal
      open={open}
      aria-labelledby="loading"
      aria-describedby="loading"
    >
      <LoaderContent>
        <CircularProgress color='primary' sx={{mb:0}} />
      </LoaderContent>
    </CustomModal>
  );
};

export default MUILoader;