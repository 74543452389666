
import { AUTH_TOKEN } from "./constant";
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const validateNumberField = (event) => {
  const allowedChars = /^[0-9\.\+\-\*\/]|[Backspace|Delete|ArrowUp|ArrowDown|ArrowLeft|ArrowRight]$|^$/;
  return allowedChars.test(event.key) && event.key !== 'e' && event.key !== 'E';
}

export const formatDateToUTC = (date) => {
  const utcDate = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
  return utcDate;
}

export const formatDateWithTimezone = (date) => {
  const timezone = momentTimeZone.tz.guess();
  const formattedDate = momentTimeZone(date).tz(timezone).format();
  return formattedDate;
}