import React from "react";
    import ReactDOM from "react-dom/client";
    import "./index.css";
    import App, { AuthRedirect } from "./App";
    import AuthProvider from "./components/AuthProvider/AuthProvider";
    import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider } from "./context/DataContext";
    
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <AuthProvider>
          <DataProvider>
            <Router>
              <AuthRedirect />
              <App />
            </Router>
          </DataProvider>
        </AuthProvider>
      </React.StrictMode>
    );