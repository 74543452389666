import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Button,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarChartIcon from '@mui/icons-material/BarChart';
import makeRequest from '../makeRequest';
import { useNavigate } from 'react-router-dom';
import PostStatsChart from '../components/PostStatsChart';
import MUIModal from '../components/MUIModal';


const ViewStatsTable = () => {
    const [selectedPost, setSelectedPost] = useState(undefined)
    const [statsData, setStatsData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const fetchData = async (page, rowsPerPage) => {
        try {
            const params = {
                page: page + 1,
                pageSize: rowsPerPage,
                period: 'inMonth'
            };

            const response = await makeRequest({
                method: 'GET',
                url: '/api/posts/grouped-by-metric',
                params
            });
            const { data, pagination } = response;
            setStatsData(data);
            setTotalRows(pagination.total);
        } catch (error) {
            console.error('Failed to fetch post stats:', error);
        }
    };
    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleViewChart = (rowId) => {
        setSelectedPost(rowId)
        setOpen(true)
    }

    const handleClose = () => {
        setSelectedPost(undefined)
        setOpen(false)
    }

    return (
        <Paper>
            <TableContainer>
                <Table aria-label="post stats table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Post ID</TableCell>
                            <TableCell>Post Content</TableCell>
                            <TableCell>Likes</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statsData.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.content}</TableCell>
                                <TableCell>{row.total_likes}</TableCell>
                                <TableCell>{row.total_comments}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>
                                    <Tooltip title="View Post">
                                        <IconButton variant="contained" onClick={() => window.open(row.post_url, '_blank')}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View Stats">
                                        <IconButton variant="contained" onClick={() => handleViewChart(row.id)}>
                                            <BarChartIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={totalRows}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />

            {selectedPost && <MUIModal
                maxWidth='md'
                title={`Post Statistics - Post Id: ${selectedPost}`}
                open={open}
                handleClose={handleClose}
            >
                <PostStatsChart postId={selectedPost} />
            </MUIModal>}

        </Paper>
    );
};

export default ViewStatsTable;
