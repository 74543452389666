import React, { useMemo, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import makeRequest from '../makeRequest';
import * as moment from 'moment';
import Box from '@mui/material/Box';
import FilterButtonGroup from './FilterButtonGroup';


const PostStatsChart = ({ postId }) => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const initialPreriod = 'last24hours';
    const [period, setPeriod] = useState(initialPreriod);


    const formatDataForChart = (likeData, commentData) => {
        const allTimestamps = new Set([
            ...likeData.map(item => item.time),
            ...commentData.map(item => item.time)
        ]);

        const formattedData = Array.from(allTimestamps).map(time => {
            const likeEntry = likeData.find(item => item.time === time);
            const commentEntry = commentData.find(item => item.time === time);
            return {
                time: moment(time).format('YYYY-MM-DD'),
                // time:  moment(time).format('YYYY-MM-DD hh:mm A'),
                // time: new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                like: likeEntry ? likeEntry.count : 0,
                comment: commentEntry ? commentEntry.count : 0
            };
        });

        return formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
    };

    const likeData = data.filter(item => item.metric_name === 'like').map(item => ({
        time: moment(item.metric_tm).format('YYYY-MM-DD hh:mm A'),
        // time: new Date(item.metric_tm).getTime(),
        count: item.count
    }));

    const commentData = data.filter(item => item.metric_name === 'comment').map(item => ({
        time: moment(item.metric_tm).format('YYYY-MM-DD hh:mm A'),
        // time: new Date(item.metric_tm).getTime(),
        count: item.count
    }));

    const chartData = formatDataForChart(likeData, commentData);

    const handleDurationSelect = (duration) => {
        if (typeof duration === 'object') {
            setPeriod(null)
            setStartDate(duration.startDate);
            setEndDate(duration.endDate);

        } else {
            const periodState = {
                'last 24 hours': 'last24hours',
                'last week': 'inWeek',
                'last month': 'inMonth'
            };
            setStartDate(null);
            setEndDate(null);
            setPeriod(periodState[duration]);
        }
    }


    useEffect(() => {
        if (!postId) return;

        let url = `/api/posts/grouped-by-metric-chart?postId=${postId}`;

        if(startDate && endDate){
            url +=`&startDate=${startDate}&endDate=${endDate}`;
        }
        
        if(period){
            url +=`&period=${period}`;
        }

        const fetchPostStats = async () => {
            await makeRequest({
                method: 'GET',
                url,
            }).then(respData => {
                setData(respData);
            }).catch(err => {
                console.error(`Error fetching post stats:`, err);
            }).finally(() => {
                setLoading(false);
            });
        };

        fetchPostStats();
    }, [postId, period, startDate, endDate]);

    return (
        <Box>
            <Box display="flex" justifyContent="flex-start" py={2}>
                <FilterButtonGroup onPeriodChange={handleDurationSelect}/>
            </Box>

            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis
                        allowDecimals={false}
                    />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="comment" stroke="#8884d8" />
                    <Line type="monotone" dataKey="like" stroke="#82ca9d" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default PostStatsChart;
