import React, { useState } from 'react';
import { TextField, Button, Typography, Box, MenuItem, Grid, CircularProgress, } from '@mui/material';
import { useDataContext } from '../context/DataContext';
import makeRequest from '../makeRequest';
import SamplePrompt from '../components/SamplePrompt';
import MUILoader from '../components/MUILoader';
import { useNavigate } from 'react-router-dom';
import { formatDateToUTC, formatDateWithTimezone, validateNumberField } from '../helpers';


const SchedulePost = () => {
    const navigate = useNavigate();
    const [product, setProduct] = useState('');
    const [postType, setPostType] = useState('');
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [startDate, setStartDate] = useState('');
    const [numOfDays, setNumDays] = useState('');
    const [socialMedia, setSocialMedia] = useState('');
    const [channel, setChannel] = useState('');
    const [isLoading,setIsLoading] =  useState(false);

    const { products, channels, medias, postTypes, loading } = useDataContext();

    const handleKeyDown = (event) => {
        if (!validateNumberField(event)) {
            event.preventDefault();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const startDateObj = new Date(startDate);
        // const utcStartDate = formatDateToUTC(startDateObj);
        const startDateZone = formatDateWithTimezone(startDateObj);
        const postData = {
            product,
            channel,
            socialMedia,
            postType,
            message,
            startDate: startDateZone,
            numOfDays,
        }
        console.log('postData: ', postData);
        setIsLoading(true);
        await makeRequest({
            method: 'POST',
            url: '/api/chatgpt/automate-post-create',
            data: postData
        }).then(respData => {
            if (respData?.response) {
                setResponse(respData.response);
            }
        }).catch(err => {
            console.error(`ChatGPT request Error`, err);
        }).finally(() => {
            setIsLoading(false);
            setTimeout(() => {
                navigate('/calendar')
            }, 5000);
        });
    };

    if(loading){
        return <CircularProgress color='primary' />
    }
    return (
        <Box sx={{ m: 1, mt: 4 }}>
            {isLoading && <MUILoader open/>}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                        <TextField
                            select
                            label="Select Product"
                            fullWidth
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            required
                        >
                            {products.map((pItem) => (
                                <MenuItem key={pItem.id} value={pItem.id}>
                                    {pItem.attributes.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            select
                            label="Select Social Media"
                            fullWidth
                            value={socialMedia}
                            onChange={(e) => setSocialMedia(e.target.value)}
                            required
                        >
                            {medias.map((mItem) => (
                                <MenuItem key={mItem} value={mItem}>
                                    {mItem}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                <Grid item xs={12} md={4}>
                        <TextField
                            select
                            label="Select Channel"
                            fullWidth
                            value={channel}
                            onChange={(e) => setChannel(e.target.value)}
                            required
                        >
                            {channels?.length && channels.map((chan) => (
                                <MenuItem key={chan.id} value={chan.id}>
                                    {chan.attributes.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            select
                            label="Select Post Type"
                            fullWidth
                            value={postType}
                            onChange={(e) => setPostType(e.target.value)}
                            required
                        >
                            {postTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Start Date"
                            type="datetime-local"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Number of Days"
                            type="number"
                            fullWidth
                            value={numOfDays}
                            onChange={(e) => setNumDays(Number(e.target.value))}
                            required
                            InputProps={{ inputProps: { min: 1 } }}
                            onKeyDown={handleKeyDown}
                        />
                    </Grid>
                    
                    
                    <Grid item xs={12}>
                        {socialMedia && numOfDays && <Box component="div" display="flex" justifyContent="flex-end" pb={1}>
                            <SamplePrompt socialMedia={socialMedia} numOfDays={numOfDays} />
                        </Box>}
                        <TextField
                            label="Enter your Prompt"
                            multiline
                            maxRows={4}
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" type="submit" fullWidth>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {response && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Response:
                    </Typography>
                    <Typography variant="body1">{response}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default SchedulePost;
