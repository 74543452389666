import axios from 'axios';
import { BASE_URL } from './constant';
import { getToken } from './helpers';

const makeRequest = (config, withAuth = true) => {
  const defaultConfig = {
    baseURL: BASE_URL,
    headers: {},
  };
  const authToken = getToken();
  const mergedConfig = { ...defaultConfig, ...config };

  if (withAuth) {
    mergedConfig.headers['Authorization'] = `Bearer ${authToken}`;
  }
  return axios(mergedConfig)
    .then(response => response.data)
    .catch(error => {
      console.error('Error:', error);
      throw error;
    });
};

export default makeRequest;