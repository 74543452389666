import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import MUIDrawer from '../../components/MUIDrawer';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



function ResponsiveAppBar() {
  const [open, setOpen] = useState(false);
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null); // State for dropdown

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  
  const handleLogout = () => {
    handleMenuClose();
    logout();
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ minHeight: '40px !important', 
        height:'50px !important', 
        paddingY: 0 }}>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => {
          setOpen(!open)
        }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Frappe....!
        </Typography>
        {user ? (
          <>
            <IconButton color="inherit" onClick={handleMenuOpen} disableRipple sx={{ pr: 0.5,}}>
            <Avatar sx={{ bgcolor: 'gray.300', marginRight: 1, color: 'black' }}>
              {user.username[0].toUpperCase()}
            </Avatar>
            {/* <Typography variant="h6" sx={{ textTransform: 'none' }}>
              {user.username}
            </Typography> */}
          </IconButton>

          {/* Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right', // Align the menu to the right of the anchor
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right', // Ensure the menu appears aligned with the anchor
            }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          <MUIDrawer open={open} onToggle={toggleDrawer} />
        </>
      ) : (
        // Login and Signup buttons if no user is logged in
        <>
          <Button color="inherit" href="/login">
            Login
          </Button>
          <Button color="inherit" href="/signup">
            SignUp
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

function HomePage() {
  return (
    <div>
      <ResponsiveAppBar />
    </div>
  );
}

export default HomePage;
