import React from 'react';
import CalendarComponent from "../../components/calendar";

const CalendarPage = () => {
  return (
    <div className="calendar-page">
      <CalendarComponent />
    </div>
  );
};

export default CalendarPage;
