import React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import CalendarPage from "../pages/CalendarPage/CalendarPage";
import SignUp from "../pages/SignUp/SignUp";
import SchedulePost from "../pages/SchedulePost";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotFound from "../pages/NotFound";
import PostStatsChart from "../components/PostStatsChart";
import ViewStatsTable from "../pages/ViewStats";
import Settings from "../pages/Settings";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicRoute restricted={true} element={<SignIn/>} />} />
      <Route
        path="/signup"
        element={<PublicRoute restricted={true} element={<SignUp />} />}
      />
      <Route
        path="/login"
        element={<PublicRoute restricted={true} element={<SignIn />} />}
      />

      <Route element={<PrivateRoute />}>
        <Route path="/settings" element={<Settings />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/schedule-post" element={<SchedulePost />} />
        <Route path="/view-stats" element={<ViewStatsTable />} />
        <Route path="/view-post-stats/:postId" element={<PostStatsChart />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
