// import axios from "axios";
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getToken, setToken } from "../helpers";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAuthContext } from "../context/AuthContext";
// import { API } from "../constant";
import CustomToolbar from "./CustomToolbar/toolbar";
// import ResponsiveAppBar from "../pages/HomePage/HomePage";
import makeRequest from "../makeRequest";
import { Box, Modal, Typography} from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom"; // Import useSearchParams
import { SlLike } from "react-icons/sl";
import { FaRegComment } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";

const localizer = momentLocalizer(moment);
const jwt_token = getToken()

function CalendarComponent() {
    const [eventsByDate, setEventsByDate] = useState({});
    const [loading, setLoading] = useState(true);
    const { user} = useAuthContext(); 
    const [view, setView] = useState("month");  // Store the current view (month, day, etc.)
    const [currentDate, setCurrentDate] = useState(new Date()); // Store the current date
    // const [filterStatus, setFilterStatus] = useState(''); // State for storing filter status

    const [searchParams, setSearchParams] = useSearchParams(); // Initialize searchParams hook
    // const filterStatusParam = searchParams.get("filterStatus") || ''; // Get filterStatus from query params
    const filterStatusParam = searchParams.get("filter") || ''; 
    const [filterStatus, setFilterStatus] = useState(filterStatusParam);
    const navigate = useNavigate(); // To navigate programmatically
    const [selectedEvent, setSelectedEvent] = useState(null); // For storing the selected event

    useEffect(() => {
        if (filterStatus) {
            setSearchParams({filter: filterStatus });
        } else {
            searchParams.delete("filter");
            setSearchParams(searchParams);
        }
    }, [filterStatus, setSearchParams, searchParams]);
     
    const fetchEventData = async () => {
        let page = 1;
        let pageSize = 100;
        let allEvents = [];
        let totalPosts = 0;
        try {
            if (!user || !user.username) {
                console.error("User not found or not logged in.");
                return;
            }
            console.log("Filtering by username:", user.username);
            console.log("user", user)

            const params = {
                filters: {
                    scheduled_by: { username: { $eq: user.username } },
                },
                pagination: { page, pageSize }
            };
            // Add status filter if filterStatus is not empty
            if (filterStatus) {
               params.filters.status = { $eq: filterStatus.toLowerCase() };
            }
            do {
                // const response = await axios.get(`${API}/posts?filters[scheduled_by][username][$eq]=${user.username}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`, {
                
                // const response = await axios.get(`${API}/posts?`, {
                //     params,
                //     headers: {
                //         'Authorization': `Bearer ${jwt_token}`
                //     },
                // });
                const response = await makeRequest({
                    method: 'GET',
                    url: `/api/posts?publicationState=preview`,
                    params,
                });

                // const response = await axios.get(`${API}/posts`, {
                //     params: {
                //         filters: {
                //             status: {
                //                 $eq: 'error',  // Filter by status where it equals 'draft'
                //             },
                //         },
                //         pagination:{
                //             page :1,
                //             pageSize : 200
                //         }
                //     },
                // });
                console.log("jwt", jwt_token)
                console.log('API response', response.data);

                const posts = response.data;
                totalPosts = response.meta.pagination.total;
                allEvents = [...allEvents, ...posts];
                page += 1;
            } while (allEvents.length < totalPosts); // Loop until all pages are fetched
            console.log('totalPosts: ', totalPosts);
            console.log("Filtered events for user:", allEvents);

            let eventsData = allEvents.map((post) => {
                const attributes = post.attributes;
                const status = attributes.status.toLowerCase();
                const content = attributes.content;

                
                let date, time;

                if (status === "published") {
                    date = moment(attributes.tm_posted).format("YYYY-MM-DD");
                    time = moment(attributes.tm_posted).format("HH:mm");
                } else {
                    date = moment(attributes.tm_scheduled).format("YYYY-MM-DD");
                    time = moment(attributes.tm_scheduled).format("HH:mm");
                }

                return {
                    date,
                    content,
                    time,
                    status,
                };
            });

            // Sorting the events by both date and time
            eventsData.sort((a, b) => {
                const dateA = moment(a.date + 'T' + a.time);
                const dateB = moment(b.date + 'T' + b.time);
                return dateA - dateB;
            });

            // Group events by date
            const groupedEvents = eventsData.reduce((acc, event) => {
                const date = event.date;
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(event);
                return acc;
            }, {});

            setEventsByDate(groupedEvents);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };
    
    useEffect(() => {
        if (user && user.id) {
            fetchEventData();
        }
    }, [user, filterStatus]);
  
    const handleSelectEvent = (event) => {
        const selectedDate = moment(event.start).toDate(); // Get the date of the clicked event
        setCurrentDate(selectedDate); // Set the calendar's current date to the event date
        setView("day"); // Switch to day view
    };
    // Handle navigation change - this makes sure the date is correct when switching views
    const handleNavigate = (newDate) => {
        setCurrentDate(newDate); // Update the current date to the newly navigated date
    };

    // Event color customization based on the status
    const eventStyleGetter = (event) => {
        const status = event.attributes?.status?.toLowerCase();
        let backgroundColor = "#3174ad";
        switch (event.status.toLowerCase()) {
            case "published":
                backgroundColor = "#28a745"; // Green
                break;
            case "draft":
                backgroundColor = "#ffc107"; // Yellow
                break;
            case "error":
                backgroundColor = "#dc3545"; // Red
                break;
            case "waiting":
                backgroundColor = "#17a2b8"; // Blue
                break;
            default:
                backgroundColor = "#6c757d"; // Grey for any other status
                break;
        }

        return {
            style: {
                backgroundColor,
                borderRadius: "0px",
                opacity: 0.8,
                color: "white",
                border: "0px",
                //padding: "5px",
                display: 'block',
            },
        };
    };

    return (
        <Box component='div' m={2}>

            {loading ? (
                <p>Loading...</p>
            ) : (
                <Calendar
                    dayLayoutAlgorithm='no-overlap'
                    localizer={localizer}
                    defaultDate={new Date()}
                    date={currentDate} // Use the selected event's date
                    view={view}  // Set the default view dynamically
                    views={["month", "week", "day"]}
                    components={{
                        toolbar: (props) => (
                            <CustomToolbar
                                {...props}
                                filterStatus={filterStatus} // Pass filterStatus to toolbar
                                setFilterStatus={setFilterStatus} // Pass setFilterStatus to toolbar
                            />
                        ),
                    }}
                    events={Object.keys(eventsByDate).reduce((acc, date) => {
                        return acc.concat(eventsByDate[date].map(event => ({
                            start: moment(date + 'T' + event.time).toDate(),
                            end: moment(date + 'T' + event.time).add(1, 'hour').toDate(),
                            title: event.content,
                            status: event.status, // Include status for event coloring
                        })));
                    }, [])}
                    style={{ height: "100vh" }}
                    onView={setView}  // Update the view when it changes
                    onSelectEvent={handleSelectEvent}  // Handle event selection
                    onNavigate={handleNavigate} // Handle date navigation
                    eventPropGetter={eventStyleGetter} // Apply color customization
                />
            )}
        </Box>
    );
}

export default CalendarComponent;