import React, { useState , useEffect} from 'react';
import { Dropdown, Button, Menu } from 'antd';

const CustomToolbar = ({ label, onNavigate, onView, filterStatus, setFilterStatus , view, setView }) => {
  const [viewLabel, setViewLabel] = useState('View'); // Default view
  
  const buttonStyle = { width: '64.14px', height: '29.24px',display: 'flex', alignItems: 'center', justifyContent: 'center' };
 
  useEffect(() => {
    if (view === 'month') setViewLabel("Monthly");
    else if (view === 'week') setViewLabel("Weekly");
    else if (view === 'day') setViewLabel("Daily");
  }, [view]);

  const labelStyle = {
    fontSize: '1.5rem',         
    // fontWeight: 'bold',          
    color: '#333',               
    // textShadow: '1px 1px 2px rgba(0,0,0,0.2)',  
    margin: '0 20px'             
  };
  const filterMenu = (
    <Menu
      onClick={(e) => setFilterStatus(e.key)}
      items={[
        { key: '', label: 'All' },
        { key: 'Draft', label: 'Draft' },
        { key: 'Published', label: 'Published' },
        { key: 'Error', label: 'Error' },
        { key: 'Waiting', label: 'Waiting' },
      ]}
    />
  );

  const viewMenu = (
    <Menu
    onClick={(e) => {
        const selectedView = e.key;
        onView(selectedView);
        setViewLabel(e.item.props.children); 
      }}
      items={[
        { key: 'month', label: 'Monthly' },
        { key: 'week', label: 'Weekly' },
        { key: 'day', label: 'Daily' },
      ]}
    />
  );

  return (
    <div className="rbc-toolbar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: '10px' }}></div>
        <Dropdown overlay={viewMenu} trigger={['click']}>
          <Button style={buttonStyle}>
          {viewLabel}
          </Button>
        </Dropdown>
      {/* </span> */ }
      <span className="rbc-toolbar-label" style={labelStyle}>{label}</span>
      
      <button type="button" onClick={() => onNavigate('PREV')}>
        {'<<'}
      </button>
      <button type="button" onClick={() => onNavigate('TODAY')} >
        Today
      </button>
      <button type="button" onClick={() => onNavigate('NEXT')}>
        {'>>'}
      </button>
        <Dropdown overlay={filterMenu} trigger={['click']}>
          <Button style={buttonStyle} >
            {filterStatus || "Filter"}
          </Button>
        </Dropdown>
      {/* </span> */}
    </div >
  );
};

export default CustomToolbar;
