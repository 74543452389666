import React from 'react'
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import MUILoader from "../components/MUILoader";

function PublicRoute({ element, restricted }) {
    const { user, isLoading } = useAuthContext();

    if (isLoading) {
        return <MUILoader open />;
    }
    if (user && restricted) {
        return <Navigate to="/calendar" />;
    }
    return element;
};

export default PublicRoute